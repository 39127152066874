
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@use '../../style/skeletonGrids.scss';
@import '@bt-branding/colors';
@import '../../style/globalColorVars.scss';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

//Channel hero banner skeleton styles

.single-channel-logo-wrap {
 background-color: $bt-tertiary;
 height: 60px;
 width: 60px;
 @include media-breakpoint-up(sm) {
  height: 163px;
  width: 163px;
 }
}

.channel-title {
 background-color: $bt-tertiary;
 height: 36px;
 width: 90%;
 margin-bottom: 14px;
}
.channel-strapline-mobile {
 background-color: $bt-tertiary;
 height: 24px;
 width: 80%;
 margin-bottom: 14px;
}
.channel-strapline-desktop {
 display: none;
 background-color: $bt-tertiary;
 height: 24px;
 width: 80%;
 margin-bottom: 14px;
}

@include media-breakpoint-up(sm) {
 .channel-strapline-desktop {
  display: block;
 }
 .channel-strapline-mobile {
  display: none;
 }
}

.channel-description {
 background-color: $bt-tertiary;
 height: 60px;
 @include media-breakpoint-up(md) {
  height: 80px;
 }
 @include media-breakpoint-up(lg) {
  height: 40px;
 }
 width: 80%;
}

.li-item {
 display: inline-block;
 background-color: $bt-tertiary;
 height: 20px;
 width: 80px;
 margin-top: 20px;
 margin-right: 30px;
}
